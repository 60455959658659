<template>
    <div class="page-wrapper">
      <div class="container-fluid">
        <div class="row page-titles">
          <div class="col-md-5 align-self-center"></div>
          <div class="col-md-7 align-self-center text-end">
            <div class="d-flex justify-content-end align-items-center">
              <ol class="breadcrumb justify-content-end">
                <li class="fw-bold h3"><span>Moyens de paiement</span></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
  
      <section class="spinnerWindow" v-show="spinner">
        <div class="spinner" >
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
       </section>
      
      
      <div class="form-body">
        <div class="card-body">
          <div class="row pt-3">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">libellé</label>
                <input
                  type="text"
                  
                  class="form-control"
                  placeholder="libellé"
                  v-model="libelle"
                />
              </div>
            </div>
            <!--/span-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Montant maximal</label>
                <input
                  type="text"
                  class="form-control form-control-danger"
                  placeholder="Montant maximal"
                  v-model="montant_max"
                />
              </div>
            </div>
            <!--/span-->
          </div>
         
          
        </div>
        <div class="form-actions">
          <div class="card-body">
            <button
              type="submit"
              @click.prevent="poster_user"
              :disabled="
              montant_max === null &&
                libelle === null 
              "
              class="btn btn-lg mx-2 btn-user text-white"
            >
              Créer
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer class="my_footer"></Footer>
  </template>
  <script>
  
  import Swal from "sweetalert2";
  // import Header from "@/components/header";
  // import Menu from "@/components/menu";
  // import Footer from "@/components/footer";
  
  import axios from "axios";
  import { lien } from "/src/assets/api.js";
  export default {
    name: "User",
    components: {
      // Header,
      // Menu,
      // Footer,
    },
  
    //+++++ DATA ++++//
    data() {
      return {
  
        libelle:"",
        montant_max:"",
        showCreated:false,
        role:null,
        registre:null,
        spinner:false,
      };
    },
  
    //+++++ METHODES ++++//
    methods: {
      poster_user() {
        this.spinner = true
     
        axios
          .post(lien + "/api/moyens", {
            libelle: this.libelle,
            max: this.montant_max,
          })
          .then((reponse) => {
            //console.log("POSTERUSER", reponse.data);
            if (reponse.data.status === true) {
              this.spinner = false
              Swal.fire({
                text: "Moyen de paiement crée",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
              });
             
             setTimeout(()=>{
                this.$router.push("/moyens_paiement")
             },1500)
            }
            if(reponse.data.status === false) {
              this.spinner = false
              Swal.fire({
                text: "Veuillez remplir tous les champs",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
                text: "Veuillez remplir tous les champs",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
              });
              this.spinner = false
          });
      },

     
  
  
    
    },
  };
  </script>
  <style scoped>
  .spinner{
    background-color:rgb(27, 27, 27) !important;
    width:45px;
    height:45px;
    padding:.5em 0 0 0 ;
    border-radius:5px;
  }
  .spinnerWindow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(103, 101, 101, 0.295);
    z-index: 99;
    display: flex;
    place-items: center;
    justify-content: center;
  }
  .my_footer{
  position:relative;
  width:100%;
  bottom:-7em;
  margin-left: 0 !important;
  }
  select {
    padding: 0.589em !important;
  
    border-radius: 0.25rem;
  }
  select:active {
    border: 1px solid rgb(134, 134, 134);
  }
  .bouton_create-role{
  text-align: left;
  margin-left:1.5em;
  }
  .bouton-annuler{
  background: rgb(231, 202, 15) !important;
  border:1px solid white !important;
  font-weight: bold;
  }
  .btn-valider,.btn-user{
  background: rgb(231, 202, 15) !important;
  border:3px solid rgb(0, 0, 0) !important;
  color:black !important;
  }
  .btn-creer-role{
  background: rgb(231, 202, 15) !important;
  border:3px solid rgb(0, 0, 0) !important;
  color:black !important;
  }
  .created_role{
  position:fixed;
  top:0;
  left: 0;
  width:100%;
  height:100%;
  background: rgba(0, 0, 0, 0.405);
  display:flex;
  place-items: center;
  justify-content: center;
  }
  .from_create_role{
  background: rgb(253, 251, 251);
  padding: 5em;
  margin-left:10%;
  }
  input,select{ 
    border: 1px solid black !important;
  }
  .form-group{ 
    text-align: left !important;
  }
  label{
    font-weight:bold !important;
  }
  </style>
  